import { pdf } from "@react-pdf/renderer";
import Cookies from "js-cookie";
import moment from "moment";
import {
  isAndroid,
  isDesktop,
  isIOS,
  isMacOs,
  isMobile,
  isTablet,
  isWindows,
} from "react-device-detect";
import RecentSearches from "recent-searches";
import ResumePdf from "../components/profile/ResumePdf/ResumePdf";
import * as config from "../config/config";
import { store } from "../redux/stores/store";

const groupArrayByPage = (itemPerPage, items) => {
  let objectOfArray = {};
  let cArray = [];
  let cIndex = 0;
  let cPage = 0;
  items.map((cItem) => {
    cArray.push(cItem);

    cIndex++;
    if (cIndex == itemPerPage) {
      objectOfArray[cPage] = cArray;
      cPage++;
      cIndex = 0;
      cArray = [];
    }
  });

  if (cArray.length > 0) {
    objectOfArray[cPage] = cArray;
  }

  return objectOfArray;
};

const getTracksFromJobs = (jobs) => {
  let tracks = [];
  let distinctTracks = {};
  let hasOther = false;

  jobs.map((job) => {
    for (let i = 0; i < job.tracks.length; i++) {
      if (job.tracks[i].title.toLowerCase() == "other") {
        hasOther = true;
      } else {
        if (
          distinctTracks[job.tracks[i].title] == null ||
          distinctTracks[job.tracks[i].title] == undefined
        ) {
          distinctTracks[job.tracks[i].title] = 1;
          tracks.push(job.tracks[i].title);
        }
      }
    }
  });

  tracks.sort();

  if (hasOther) {
    //Add other as the last track
    tracks.push("Other");
  }

  return tracks;
};

const getGraduationYears = () => {
  //Generate graduation Years
  let fromDate = moment();
  let toDate = moment();

  fromDate.subtract(50, "year");
  toDate.add(5, "year");

  let graduationYears = [];

  for (let i = fromDate.get("year"); i <= toDate.get("year"); i++) {
    graduationYears.push(i.toString());
  }

  return graduationYears.reverse();
};

const getMinMaxFromCurrentYear = () => {
  //Generate graduation Years
  let fromDate = moment();
  let toDate = moment();

  fromDate.subtract(100, "year");
  toDate.add(5, "year");

  let graduationYears = [];

  for (let i = fromDate.get("year"); i <= toDate.get("year"); i++) {
    graduationYears.push(i.toString());
  }

  return graduationYears.reverse();
};

const convertBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

const isAiviSession = (chat) => {
  let isAivi = false;
  if (chat.members) {
    chat.members.map((member) => {
      if (
        member.metaData &&
        member.metaData.role &&
        member.metaData.role.toLowerCase() == "bot"
      ) {
        isAivi = true;
      }
    });

    return isAivi;
  }

  return false;
};

const getFilteredChats = (chats, searchValue) => {
  if (searchValue != "") {
    let filteredChats = chats.filter((chat) => {
      if (chat.name.toLowerCase().includes(searchValue)) {
        return true;
      }

      //check for company name via member.nickname of user_type == "master"
      if (chat.members) {
        let matchValue = false;
        chat.members.forEach((member) => {
          if (
            member.metaData &&
            member.metaData.user_type &&
            member.metaData.user_type.toLowerCase() == "master"
          ) {
            if (
              member.nickname &&
              member.nickname.toLowerCase().includes(searchValue)
            ) {
              matchValue = true;
            }
          }
        });

        if (matchValue) return matchValue;
      }
      return false;
    });

    return filteredChats;
  }

  return chats;
};

const formatDate = (date, dateFormat) => {
  if (date) {
    return moment.utc(date).add(8, "hours").format(dateFormat);
  }

  return moment().format(dateFormat);
};

const upperCaseFirstLetter = (value) => {
  if (value) {
    return value.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  return "";
};

const convertExpectedSalary = (expected_salary) => {
  let drag_value = 1;
  let currentSalary = 0;

  while (drag_value <= 50) {
    currentSalary = calibrateSalary(drag_value);

    if (currentSalary == expected_salary) {
      break;
    } else if (currentSalary > expected_salary) {
      drag_value -= 1;
      break;
    }

    drag_value += 1;
  }

  return drag_value;
};

const calibrateSalary = (point) => {
  let min_salary = 0;

  if (point <= 25) {
    min_salary = 200 * point;
  } else if (point > 25 && point <= 35) {
    min_salary = 25 * 200 + (point - 25) * 500;
  } else if (point > 35) {
    min_salary = 25 * 200 + 10 * 500 + (point - 35) * 1000;
  }

  return min_salary;
};

const setItem = (key, value) => {
  try {
    localStorage.setItem(key, value);
  } catch (error) {
    //
  }
};

const getItem = (key) => {
  let value;
  try {
    value = localStorage.getItem(key);
  } catch (error) {
    //
  }

  return value;
};

const removeItem = (key) => {
  try {
    localStorage.removeItem(key);
  } catch (error) {
    //
  }
};

const getCompanyCoverImage = (url) => {
  const companyCoverImage = url
    ? url.includes("https:")
      ? url
      : `https:${url}`
    : config.assetDomain +
      "/images/backgrounds/hiredly-jobs-malaysia-wobb-background-v1.png";

  return companyCoverImage;
};

const getCompanyImage = (url) => {
  const companyOfficeImage = url.includes("https:") ? url : `https:${url}`;

  return companyOfficeImage;
};

const getJobCoverImage = (url) => {
  const jobCoverImage = url
    ? url.includes("https:")
      ? url
      : `https:${url}`
    : config.assetDomain +
      "/images/backgrounds/hiredly-jobs-malaysia-wobb-background-v1.png";

  return jobCoverImage;
};

const getCompanyLogoImage = (url) => {
  const companyLogoImage = url
    ? url
      ? url?.includes("https:")
        ? url
        : `https:${url}`
      : config.assetDomain +
        "/images/backgrounds/hiredly-jobs-malaysia-wobb-background-v1.png"
    : config.assetDomain +
      "/images/backgrounds/hiredly-jobs-malaysia-wobb-background-v1.png";
  return companyLogoImage;
};

const generateDeviceType = () => {
  let platform = window.navigator.platform;
  let deviceType = "web";

  if (isTablet) {
    deviceType += "_tablet";
  } else if (isMobile) {
    deviceType += "_mobile";
  } else if (isDesktop) {
    deviceType += "_desktop";
  } else {
    deviceType += "_null";
  }

  if (isAndroid) {
    deviceType += "_android";
  } else if (isIOS) {
    deviceType += "_ios";
  } else if (isWindows) {
    deviceType += "_windows";
  } else if (isMacOs) {
    deviceType += "_macintosh";
  } else if (/Linux/.test(platform)) {
    deviceType += "_linux";
  } else {
    deviceType += "_null";
  }

  return deviceType;
};

const removeCommonWordFromJobTitle = (title) => {
  if (!title) return "";

  let commonWords = ["junior", "senior", "executive"];

  var expStr = commonWords.join("|");
  return title
    .toLowerCase()
    .replace(new RegExp("\\b(" + expStr + ")\\b", "gi"), "")
    .replace(/\s{2,}/g, "")
    .trim();
};

const searches = new RecentSearches({
  namespace: "recent-searches",
  ranking: "TIME",
  limit: 5,
});

const fetchRecentSearches = () => {
  return searches.getRecentSearches("recent-searches");
};

const updateRecentSearches = (keyword) => {
  let resultData = { query: keyword };
  searches.setRecentSearch(resultData.query, resultData);
};

const getCompanyIndustriesTitle = () => {
  let companyIndustries = store.getState().companies.industries;
  let companyIndustriesTitles = [];

  for (let i = 0; i < companyIndustries.length; i++) {
    companyIndustriesTitles.push(companyIndustries[i].title);
  }

  return companyIndustriesTitles;
};

const monthsConverter = (month) => {
  switch (month) {
    case "Jan":
      return 1;

    case "Feb":
      return 2;

    case "Mar":
      return 3;

    case "Apr":
      return 4;

    case "May":
      return 5;

    case "Jun":
      return 6;

    case "Jul":
      return 7;

    case "Aug":
      return 8;

    case "Sep":
      return 9;

    case "Oct":
      return 10;

    case "Nov":
      return 11;

    case "Dec":
      return 12;
  }
};

const sortWorkingExperiences = (workingExperiences) => {
  let cWorkingExperiences = [...workingExperiences];

  cWorkingExperiences.sort((a, b) => {
    const startDateYearA = a.startDateYear || a.start_date_year;
    const startDateYearB = b.startDateYear || b.start_date_year;
    const startDateMonthA = a.startDateMonth || a.start_date_month;
    const startDateMonthB = b.startDateMonth || b.state_date_month;
    const endDateYearA = a.endDateYear || a.end_date_year;
    const endDateYearB = b.endDateYear || b.end_date_year;
    const endDateMonthA = a.endDateMonth || a.end_date_month;
    const endDateMonthB = b.endDateMonth || b.end_date_month;

    const startValueA =
      (startDateYearA ?? 0) + (startDateMonthA ? startDateMonthA / 100 : 0);
    const startValueB =
      (startDateYearB ?? 0) + (startDateMonthB ? startDateMonthB / 100 : 0);
    const endValueA =
      (endDateYearA ?? 0) + (endDateMonthA ? endDateMonthA / 100 : 0);
    const endValueB =
      (endDateYearB ?? 0) + (endDateMonthB ? endDateMonthB / 100 : 0);

    const totalValueA =
      startValueA > 0 && endValueA === 0 ? 3000 + startValueA : endValueA;
    const totalValueB =
      startValueB > 0 && endValueB === 0 ? 3000 + startValueB : endValueB;

    if (totalValueB > totalValueA) {
      return 1;
    } else if (totalValueA > totalValueB) {
      return -1;
    }

    // both value A and B having same value then arrange based on createdAt
    if (moment(b.createdAt).isAfter(moment(a.createdAt))) {
      return 1;
    } else if (moment(b.createdAt).isBefore(moment(a.createdAt))) {
      return -1;
    }

    return 0;
  });

  return cWorkingExperiences;
};

const getLottieData = async (lotties3Url, lottieConfig, setLottieConfig) => {
  const response = await fetch(lotties3Url);

  const rawData = await response.text();

  setLottieConfig(JSON.parse(rawData));
};

const uploadGeneratedResume = async (
  userObj,
  handleCvFileChange,
  sectionSeq
) => {
  const pdfFileBlob = await pdf(
    <ResumePdf currentUser={userObj} sectionSequence={sectionSeq} />
  ).toBlob();

  const resumeFileFromBlob = new File([pdfFileBlob], "resumePDF.pdf");

  resumeFileFromBlob && handleCvFileChange(resumeFileFromBlob, true);
};

const stringifyObjValues = (obj) => {
  let localObj = {};
  Object.keys(obj).map((key) => {
    const stringifyVal = obj[key].toString();
    localObj[key] = stringifyVal;
  });

  return localObj;
};

const capitaliseString = (val) => {
  // array map and for each char at 0, upper case it, then concatenate slice 1
  const splitString = val.split(" ");
  const capitalisedStr = splitString.map(
    (string) => string.charAt(0).toUpperCase() + string.slice(1)
  );

  return capitalisedStr.join(" ");
};

const getGaSessionID = () => {
  const gaCookie = Cookies.get(process.env.GA_COOKIE_ID);
  const gaUserCookie = Cookies.get("_ga");

  if (!gaCookie || !gaUserCookie) return null;

  const gaSessionID =
    typeof gaCookie === "string" && gaCookie.split(".").length >= 2
      ? gaCookie.split(".")[2]
      : "-";

  const userPseudoID =
    typeof gaUserCookie === "string" && gaUserCookie.split(".").length > 0
      ? gaUserCookie.split(".").slice(-2).join(".")
      : "-";

  const finalSessionID = `${gaSessionID}_${userPseudoID}`;

  return finalSessionID;
};

const getBiggestYearDiff = (workExps) => {
  const sortedWorkExps = sortWorkingExperiences(workExps);

  const dateOne = moment([
    sortedWorkExps[sortedWorkExps.length - 1].startDateYear ?? moment().year(),
    sortedWorkExps[sortedWorkExps.length - 1].startDateMonth ??
      moment().month(),
  ]);

  const dateTwo = moment([
    sortedWorkExps[0].endDateYear ?? moment().year(),
    sortedWorkExps[0].startDateMonth ?? moment().month(),
  ]);

  return dateTwo.diff(dateOne, "years");
};

export {
  calibrateSalary,
  capitaliseString,
  convertBase64,
  convertExpectedSalary,
  fetchRecentSearches,
  formatDate,
  generateDeviceType,
  getBiggestYearDiff,
  getCompanyCoverImage,
  getCompanyImage,
  getCompanyIndustriesTitle,
  getCompanyLogoImage,
  getFilteredChats,
  getGaSessionID,
  getGraduationYears,
  getItem,
  getJobCoverImage,
  getLottieData,
  getMinMaxFromCurrentYear,
  getTracksFromJobs,
  groupArrayByPage,
  isAiviSession,
  removeCommonWordFromJobTitle,
  removeItem,
  setItem,
  sortWorkingExperiences,
  stringifyObjValues,
  updateRecentSearches,
  uploadGeneratedResume,
  upperCaseFirstLetter,
};

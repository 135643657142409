const setCountryApi = (params) => {
  const country = params?.country;

  const apiEndpoint = "/api/job_seeker/v1/graphql";
  const baseURL =
    process.env.NEXT_PUBLIC_JSW_ENV === "prod"
      ? "https://"
      : "https://staging-";

  const domain = "-api.hiredly.com";

  let url;

  switch (country) {
    case "sg":
      url = baseURL + "sg" + domain + apiEndpoint;
      break;
    case "my":
      url = baseURL + "my" + domain + apiEndpoint;
      break;
    default:
      break;
  }

  return url;
};

const setEmployerParams = () => {
  const env = process.env.NEXT_PUBLIC_JSW_ENV;

  let urlParams = document.cookie
    .split("; ")
    .find((row) => row.startsWith("params="))
    ?.toString()
    .replace("params=", "");

  let urlAndEnvParams = new URLSearchParams(urlParams);

  urlAndEnvParams.append(
    "country",
    process.env.NEXT_PUBLIC_JSW_GEOLOCATION ?? "my"
  );

  urlAndEnvParams.append("env", process.env.NEXT_PUBLIC_JSW_ENV ?? "staging");

  urlAndEnvParams.append("origin", "jobseeker");

  if (env == "dev") {
    return urlParams
      ? `https://dev-employer.hiredly.com/register/login-info/?${urlAndEnvParams}`
      : `https://dev-employer.hiredly.com/register/login-info/?${urlAndEnvParams}`;
  } else if (env == "staging") {
    return urlParams
      ? `https://staging-employer.hiredly.com/register/login-info/?${urlAndEnvParams}`
      : `https://staging-employer.hiredly.com/register/login-info/?${urlAndEnvParams}`;
  } else {
    return urlParams
      ? `https://employer.hiredly.com/register/login-info/?${urlAndEnvParams}`
      : `https://employer.hiredly.com/register/login-info/?${urlAndEnvParams}`;
  }
};

export { setCountryApi, setEmployerParams };

import * as types from "../types/job_type";

const initialState = {
  // For job list
  jobListTab: 1,
  fetchingJobList: true,
  jobs: [],
  jobsParams: {},
  jobPageInfo: {},
  jobPageInfoV1: {},
  jobCurrentPage: 1,
  jobReachedEnd: false,
  error: false,
  errorMessage: "",
  currentJobId: "",
  currentLoadingJobsKey: "",
  internshipFilterButtonStatus: false,
  isLoadingJobs: false,
  pageFirstLoad: true,
  // For total jobs pages
  totalJobsPages: 20,
  fetchingTotalJobsPages: false,
  totalJobsCount: 30,
  // For job suggestion on mobile filter
  jobSuggestions: [],
  suggestionPage: 1,
  fetchingJobSuggestion: false,
  currentSuggestionKey: "",
  jobSuggestionEnd: false,
  // For recommended jobs based on track
  recommendedJobs: [],
  fetchingRecommendedJobs: false,
  bookmarkedRecommendedJobs: [],
  // For job list filter jobs page
  jobListFilter: {},
  jobSearchKeyword: "",
  // For for you page
  fetchingForYou: true,
  // For job history
  historyExist: false,
  // for activity exist
  activityExist: false,
  // For job
  job: {},
  fetchingJob: false,
  fetchingJobError: false,
  fetchingBookmarkApplyStatus: false,
  // For bookmark job
  bookmarkingJob: false,
  bookmarkingJobError: false,
  // For unbookmark job
  unbookmarkJob: false,
  unbookmarkJobError: true,
  // For fetching bookmarked job list
  bookmarkedJobs: [],
  fetchingBookmarkJobList: false,
  bookmarkJobListPageInfo: {},
  fetchingBookmarkJobListError: false,
  // For apply job
  applyingJob: false,
  applyJobError: false,
  // For cancel job application
  cancellingJobApplication: false,
  cancellingJobApplicationError: false,
  // For job's company's jobs
  jobCompanyJobs: [],
  fetchingJobCompanyJob: false,
  // For applied job list
  appliedJobs: [],
  appliedJobsPageInfo: {},
  fetchingAppliedJobs: false,
  fetchingAppliedJobsError: false,
  // For pending job list
  pendingJobs: [],
  pendingJobsPageInfo: {},
  fetchingPendingJobs: false,
  fetchingPendingJobsError: false,
  // For rejected job list
  rejectedJobs: [],
  // For user landing page
  specialisation: [],
  tracks: [],
  // For job state/region
  stateRegions: [],
  // For featured jobs
  featuredJobs: [],
  fetchingFeaturedJobs: false,
  // For curated jobs
  curatedJobs: [],
  fetchingCuratedJobs: false,
  // For job types
  jobTypes: [],
  // For experience levels
  experienceLevels: [],
  // For job list filter landing page
  jobListFilterLanding: {},
  // For similar jobs under job page
  similarJobsUnderJobPage: [],
  fetchingSimilarJobsUnderJobsPage: false,
  // For similar application jobs
  similarApplicationJobs: [],
  fetchingSimilarApplicationJobs: false,
  // For onboarding recommended jobs
  onboardingRecommendedJobs: [],
  fetchingOnboardingJobs: false,
  fetchingOnboardingJobsError: false,
  // For homepage recommended jobs
  homepageRecommendedJobs: [],
  // For homepage trending searches
  trendingJobKeywords: [],
  fetchingTrendingJobKeywords: false,
  // For homepage saved jobs
  homepageSavedJobs: [],
  // For job alerts
  showJobAlertToggle: false,
  jobAlerts: [],
  fetchingJobAlerts: false,
  fetchingJobAlertsError: false,
  creatingJobAlert: false,
  creatingJobAlertError: false,
  deletingJobAlert: false,
  deletingJobAlertError: false,
  replacingJobAlert: false,
  replacingJobAlertError: false,
  updatingJobAlert: false,
  updatingJobAlertError: false,
  jobAlert: {},
  fetchingJobAlert: false,
  fetchingJobAlertError: false,
  syncJobAlertToggle: false,
  visitorJobAlert: "",
  visitorAddingJobAlert: false,
  reachedMaxJobAlerts: false,
  jobAlertExists: false,
  visitorUnsubscribingJobAlert: false,

  // For search suggestion
  searchData: [],
  searchSuggestion: "",
  fetchingSearchSuggestions: false,
  // For Ashley toggle dialog
  openAshleyInfoDialog: false,
  // For Ashley toggle state

  ashleyRecommendsError: false,
  ashleyRecommendsErrorCode: "",
  // Homepage Logo
  homepageLogo: [],
  // Homepage Trending Chips
  trendingChip: [],
  // Homepage Ads Slider
  homepageAds: [],
  jobAlertEmail: "",
  showVisitorJobAlert: false,
  visitorJobAlertSuccess: false,
  forYouPaginationCounter: 1,
  // Secondary Visitor Applied Job
  selectedJobId: "",
  // To manage predefined job filter flow
  doneCheckingUrlParamForJobFilter: false,
  // campaign microsite
  campaignInfo: {},
  // For search feedback
  searchFeedback: {
    showCard: true,
    feedbackSubmit: false,
  },
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    // For job list
    case types.FETCHING_JOBS: {
      return {
        ...state,
        fetchingJobList: true,
        fetchingTotalJobsPages: true,
      };
    }
    case types.FETCHING_ALL_JOBS: {
      return {
        ...state,
        isLoadingJobs: action.state,
        fetchingJobList: action.state,
      };
    }
    case types.FETCH_JOBS_SUCCEED: {
      const jobList = action.payload.jobList;
      let cJobList = action.payload.refresh ? [] : [...state.jobs];

      cJobList = [...cJobList, ...jobList];

      return {
        ...state,
        isLoadingJobs: false,
        fetchingJobList: false,
        jobs: jobList,
        error: false,
        totalJobsPages: action.payload.totalJobsPages,
        fetchingTotalJobsPages: false,
        totalJobsCount: action.payload.totalJobsCount,
        jobPageInfoV1: action.payload.pageInfo,
      };
    }

    case types.GET_JOBS_PARAMS: {
      const jobParams = action.payload.jobsParams;
      return {
        ...state,
        jobsParams: jobParams,
      };
    }

    case types.FETCH_JOBS_FAILED: {
      // Use back existing fetchingJobList status if key does not match

      let cFetchingJobList =
        action.currentLoadingJobsKey === state.currentLoadingJobsKey
          ? false
          : state.fetchingJobList;

      return {
        ...state,
        isLoadingJobs: true,
        fetchingJobList: cFetchingJobList,
        error: true,
        errorMessage: action.errorMessage,
        fetchingTotalJobsPages: false,
      };
    }

    case types.UPDATE_JOBS_LIST_PAGE: {
      return {
        ...state,
        jobCurrentPage: action.payload,
      };
    }

    case types.UPDATE_FETCHING_ALL_JOBS: {
      return {
        ...state,
        fetchingJobList: action.payload,
      };
    }

    // For microsite jobs
    case types.FETCH_MICROSITE_JOBS_SUCCEED: {
      let cJobList = state.jobs;
      const jobList = action?.payload?.jobList;

      cJobList.length > 0 && (cJobList = [...cJobList, ...jobList]);

      return {
        ...state,
        jobs: cJobList.length > 0 ? cJobList : jobList,
        fetchingJobList: false,
        error: false,
        jobPageInfoV1: action.payload.pageInfo,
      };
    }
    case types.FETCH_MICROSITE_JOBS_FAILED: {
      return {
        ...state,
        error: true,
        errorMessage: action.errorMessage,
      };
    }

    // for campaign jobs
    case types.FETCH_CAMPAIGN_JOBS: {
      return {
        ...state,
        fetchingJobList: true,
        error: false,
      };
    }

    case types.FETCH_CAMPAIGN_JOBS_SUCCEED: {
      let cJobList = state.jobs;
      const jobList = action?.payload?.jobs;

      cJobList.length > 0 && (cJobList = [...cJobList, ...jobList]);

      return {
        ...state,
        jobs: cJobList.length > 0 ? cJobList : jobList,
        fetchingJobList: false,
        error: false,
        jobPageInfoV1: action.payload.pageInfo,
        campaignInfo: action.payload.campaign,
      };
    }
    case types.FETCH_CAMPAIGN_JOBS_FAILED: {
      return {
        ...state,
        fetchingJobList: false,
        error: true,
      };
    }

    case types.UPDATE_INTERNSHIP_FILTER_BUTTON_STATUS: {
      return {
        ...state,
        internshipFilterButtonStatus: action.internshipFilterButtonStatus,
      };
    }
    // For Ashley recommended jobs list
    case types.FETCHING_ASHLEY_RECOMMENDED_JOBS: {
      return {
        ...state,
        fetchingJobList: true,
        fetchingTotalJobsPages: true,
      };
    }

    case types.FETCH_ASHLEY_RECOMMENDED_JOBS_SUCCEED: {
      const jobList = action.payload.jobList;
      // let cJobList = action.payload.refresh ? [] : [...state.jobs];

      // cJobList = [...cJobList, ...jobList];

      let totalPages = action.payload.totalJobsPages;
      let totalCount = action.payload.totalJobsCount;
      let pageInfo = action.payload.pageInfo;

      return {
        ...state,
        fetchingJobList: false,
        fetchingForYou: false,
        jobs: jobList,
        error: false,
        totalJobsPages: totalPages,
        fetchingTotalJobsPages: false,
        totalJobsCount: totalCount,
        jobPageInfoV1: pageInfo,
        ashleyRecommendsError: false,
        ashleyRecommendsErrorCode: "",
      };
    }
    case types.FETCH_ASHLEY_RECOMMENDED_JOBS_FAILED: {
      // Use back existing fetchingJobList status if key does not match
      let cFetchingJobList =
        action.currentLoadingJobsKey === state.currentLoadingJobsKey
          ? false
          : state.fetchingJobList;

      return {
        ...state,
        fetchingForYou: false,
        fetchingJobList: cFetchingJobList,
        error: true,
        errorMessage: action.errorMessage,
        fetchingTotalJobsPages: false,
        ashleyRecommendsError: true,
        ashleyRecommendsErrorCode: action.ashleyRecommendsErrorCode,
      };
    }

    case types.FETCHING_ASHLEY_AWS_RECOMMENDED_JOBS: {
      return {
        ...state,
        fetchingJobList: true,
        fetchingTotalJobsPages: true,
      };
    }

    case types.FETCH_ASHLEY_AWS_RECOMMENDED_JOBS_SUCCEED: {
      const jobList = action.payload.jobList;
      // let cJobList = action.payload.refresh ? [] : [...state.jobs];

      // cJobList = [...cJobList, ...jobList];

      let totalPages = action.payload.totalJobsPages;
      let totalCount = action.payload.totalJobsCount;
      let pageInfo = action.payload.pageInfo;

      return {
        ...state,
        fetchingJobList: false,
        fetchingForYou: false,
        activityExist: true,
        jobs: jobList,
        error: false,
        totalJobsPages: totalPages,
        fetchingTotalJobsPages: false,
        totalJobsCount: totalCount,
        jobPageInfoV1: pageInfo,
        ashleyRecommendsError: false,
        ashleyRecommendsErrorCode: "",
      };
    }
    case types.FETCH_ASHLEY_AWS_RECOMMENDED_JOBS_FAILED: {
      // Use back existing fetchingJobList status if key does not match
      let cFetchingJobList =
        action.currentLoadingJobsKey === state.currentLoadingJobsKey
          ? false
          : state.fetchingJobList;

      return {
        ...state,
        fetchingForYou: false,
        fetchingJobList: cFetchingJobList,
        error: true,
        errorMessage: action.errorMessage,
        fetchingTotalJobsPages: false,
        ashleyRecommendsError: true,
        ashleyRecommendsErrorCode: action.ashleyRecommendsErrorCode,
      };
    }

    case types.CLEAR_JOB_LIST: {
      return {
        ...state,
        fetchingJobList: true,
        jobCurrentPage: 1,
        jobs: [],
      };
    }
    case types.NO_JOBS_FOUND: {
      return {
        ...state,
        fetchingJobList: false,
        error: false,
        errorMessage: action.errorMessage,
      };
    }
    case types.END_OF_JOB_LIST: {
      return {
        ...state,
        jobReachedEnd: true,
        fetchingJobList: false,
        jobPageInfo: action.payload.pageInfo,
        error: false,
      };
    }
    case types.UPDATE_JOB_ID: {
      return {
        ...state,
        currentJobId: action.jobId,
      };
    }
    // For job suggestion
    case types.FETCH_JOB_SUGGESTION: {
      return {
        ...state,
        fetchingJobSuggestion: true,
      };
    }
    case types.FETCH_JOB_SUGGESTION_SUCCEED: {
      let jobSuggestionList = action.payload.refresh
        ? []
        : [...state.jobSuggestions];
      let cPage = state.suggestionPage;
      let cFetchingJobSuggestion = state.fetchingJobSuggestion;
      let jobSuggestionEnd = state.jobSuggestionEnd;

      // Only update suggestion job list if currentSuggestionKey is the same as current state value
      if (action.payload.currentSuggestionKey == state.currentSuggestionKey) {
        jobSuggestionList = [
          ...jobSuggestionList,
          ...action.payload.jobSuggestions,
        ];
        cPage = action.payload.suggestionPage + 1;
        cFetchingJobSuggestion = false;
        jobSuggestionEnd =
          action.payload.jobSuggestions.length >= 21 ? false : true;
      }

      return {
        ...state,
        fetchingJobSuggestion: cFetchingJobSuggestion,
        jobSuggestions: jobSuggestionList,
        suggestionPage: cPage,
        jobSuggestionEnd: jobSuggestionEnd,
      };
    }
    case types.FETCH_JOB_SUGGESTION_FAILED: {
      // Use back existing fetchingJobList status if key does not match
      let cFetchingJobSuggestion =
        action.payload.currentSuggestionKey == state.currentSuggestionKey
          ? false
          : state.fetchingJobSuggestion;

      return {
        ...state,
        fetchingJobSuggestion: cFetchingJobSuggestion,
      };
    }
    case types.CLEAR_JOB_SUGGESTION: {
      return {
        ...state,
        suggestionPage: 1,
        jobSuggestions: [],
      };
    }
    // For job list filter
    case types.UPDATE_JOB_LIST_FILTER: {
      return {
        ...state,
        jobListFilter: action.jobListFilter,
      };
    }
    // For job
    case types.GET_JOB: {
      return {
        ...state,
        fetchingJob: true,
        jobCompanyJobs: [], // For now we could reset this as only single job and job list is calling GET_JOB
      };
    }
    case types.GET_JOB_SUCCEED: {
      return {
        ...state,
        job: action.job,
        fetchingJob: false,
        fetchingJobError: false,
      };
    }
    case types.GET_JOB_FAILED: {
      let cFetchingJob =
        action.jobId == state.job.id ? false : state.fetchingJob;
      return {
        ...state,
        fetchingJob: cFetchingJob,
        fetchingJobError: false,
      };
    }
    // For job bookmark and apply status
    case types.GET_JOB_BOOKMARK_AND_APPLY_STATUS: {
      return {
        ...state,
        fetchingBookmarkApplyStatus: true,
      };
    }
    case types.GET_JOB_BOOKMARK_AND_APPLY_STATUS_SUCCEED: {
      return {
        ...state,
        fetchingBookmarkApplyStatus: false,
      };
    }
    case types.GET_JOB_BOOKMARK_AND_APPLY_STATUS_FAILED: {
      return {
        ...state,
        fetchingBookmarkApplyStatus: false,
      };
    }
    // For job profile
    case types.UPDATE_SELECTED_JOB: {
      return {
        ...state,
        job: action.selectedJob,
      };
    }
    // For bookmarked job list
    case types.FETCH_JOB_BOOKMARK_LIST: {
      return {
        ...state,
        fetchingBookmarkJobList: true,
      };
    }
    case types.FETCH_JOB_BOOKMARK_LIST_SUCCEED: {
      const bookmarkList = action.payload.nodes;
      let cBookmarkJobs = [...state.bookmarkedJobs, ...bookmarkList];

      return {
        ...state,
        bookmarkedJobs: cBookmarkJobs,
        bookmarkJobListPageInfo: action.payload.pageInfo,
        fetchingBookmarkJobList: false,
        fetchingBookmarkJobListError: false,
      };
    }
    case types.FETCH_JOB_BOOKMARK_LIST_FAILED: {
      return {
        ...state,
        fetchingBookmarkJobList: false,
        fetchingBookmarkJobListError: true,
      };
    }
    case types.CLEAR_JOB_BOOKMARK_LIST: {
      return {
        ...state,
        bookmarkedJobs: [],
      };
    }
    case types.UPDATE_BOOKMARKED_JOB: {
      let cBookmarkedJobs = JSON.parse(JSON.stringify(state.bookmarkedJobs));
      const index = state.bookmarkedJobs.findIndex(
        (bookmark) => bookmark.job.id === action.job.id
      );
      if (index != -1) {
        cBookmarkedJobs[index].job = action.job;
      }
      return {
        ...state,
        bookmarkedJobs: cBookmarkedJobs,
      };
    }
    // Bookmark job
    case types.BOOKMARK_JOB: {
      return {
        ...state,
        bookmarkingJob: true,
      };
    }
    case types.BOOKMARK_JOB_SUCCEED: {
      // Only replace job if bookmarked job is the selected one
      let cJob =
        action.payload.bookmarkJob?.id === state.job.id &&
        action.payload.bookmarkJob
          ? { ...state.job, bookmark: true }
          : { ...state.job };

      let jobList = [...state.jobs];
      let homepageRecommendedJobsList = [...state.homepageRecommendedJobs];
      let homepageFeaturedJobsList = [...state.featuredJobs];
      let homepageSimilarAppliedJobsList = [...state.similarApplicationJobs];
      let homepageSavedJobsList = [...state.homepageSavedJobs];
      let onboardingJobList = [...state.onboardingRecommendedJobs];

      let index = jobList.findIndex((job) => {
        return job.id == action.payload.bookmarkJob?.id;
      });

      if (index != -1) {
        jobList[index] = { ...action.payload.bookmarkJob };
      }

      let homepageIndex = homepageRecommendedJobsList.findIndex((job) => {
        return job.id == action.payload.bookmarkJob?.id;
      });

      if (homepageIndex != -1) {
        homepageRecommendedJobsList[homepageIndex] = {
          ...action.payload.bookmarkJob,
        };
      }

      let featuredJobsIndex = homepageFeaturedJobsList.findIndex((job) => {
        return job.id == action.payload.bookmarkJob?.id;
      });

      if (featuredJobsIndex != -1) {
        homepageFeaturedJobsList[featuredJobsIndex] = {
          ...action.payload.bookmarkJob,
        };
      }

      let similarJobsIndex = homepageSimilarAppliedJobsList.findIndex((job) => {
        return job.id == action.payload.bookmarkJob?.id;
      });

      if (similarJobsIndex != -1) {
        homepageSimilarAppliedJobsList[similarJobsIndex] = {
          ...action.payload.bookmarkJob,
        };
      }

      let homepageSavedJobsIndex = homepageSavedJobsList.findIndex((job) => {
        return job.id == action.payload.bookmarkJob?.id;
      });

      if (homepageSavedJobsIndex != -1) {
        homepageSavedJobsList[homepageSavedJobsIndex] = {
          ...action.payload.bookmarkJob,
        };
      }

      const onboardingJobIndex = onboardingJobList.findIndex((job) => {
        return job.id == action.payload.bookmarkJob?.id;
      });

      if (onboardingJobIndex != -1) {
        onboardingJobList[onboardingJobIndex] = {
          ...action.payload.bookmarkJob,
        };
      }

      return {
        ...state,
        jobs: jobList,
        job: cJob,
        bookmarkingJob: false,
        bookmarkingJobError: false,
        homepageRecommendedJobs: homepageRecommendedJobsList,
        featuredJobs: homepageFeaturedJobsList,
        similarApplicationJobs: homepageSimilarAppliedJobsList,
        homepageSavedJobs: homepageSavedJobsList,
        onboardingRecommendedJobs: onboardingJobList,
      };
    }
    case types.BOOKMARK_JOB_FAILED: {
      return {
        ...state,
        bookmarkingJob: false,
        bookmarkingJobError: true,
      };
    }
    // Unbookmark job
    case types.UNBOOKMARK_JOB: {
      return {
        ...state,
        unbookmarkJob: true,
      };
    }
    case types.UNBOOKMARK_JOB_SUCCEED: {
      let jobId = action.jobId;
      let cJob =
        action.job && action.job.id === state.job.id
          ? { ...state.job, bookmark: false }
          : state.job;
      let jobList = [...state.jobs];
      let homepageRecommendedJobsList = [...state.homepageRecommendedJobs];
      let homepageFeaturedJobsList = [...state.featuredJobs];
      let homepageSimilarAppliedJobsList = [...state.similarApplicationJobs];
      let homepageSavedJobsList = [...state.homepageSavedJobs];
      let onboardingJobList = [...state.onboardingRecommendedJobs];

      let bookmarkJobs = state.bookmarkedJobs.filter((bookmark) => {
        if ((bookmark.job && bookmark.job.id) == jobId) {
          return false;
        }
        return true;
      });

      let index = jobList.findIndex((job) => {
        return job.id == jobId;
      });

      if (index != -1) {
        jobList[index] = { ...action.job };
      }

      let homepageIndex = homepageRecommendedJobsList.findIndex((job) => {
        return job.id == jobId;
      });

      if (homepageIndex != -1) {
        homepageRecommendedJobsList[homepageIndex] = {
          ...action.job,
        };
      }

      let featuredJobsIndex = homepageFeaturedJobsList.findIndex((job) => {
        return job.id == jobId;
      });

      if (featuredJobsIndex != -1) {
        homepageFeaturedJobsList[featuredJobsIndex] = {
          ...action.job,
        };
      }

      let similarJobsIndex = homepageSimilarAppliedJobsList.findIndex((job) => {
        return job.id == jobId;
      });

      if (similarJobsIndex != -1) {
        homepageSimilarAppliedJobsList[similarJobsIndex] = {
          ...action.job,
        };
      }

      let homepageSavedJobsIndex = homepageSavedJobsList.findIndex((job) => {
        return job.id == jobId;
      });

      if (homepageSavedJobsIndex != -1) {
        homepageSavedJobsList[homepageSavedJobsIndex] = {
          ...action.job,
        };
      }

      const onboardingJobIndex = onboardingJobList.findIndex((job) => {
        return job.id == jobId;
      });

      if (onboardingJobIndex != -1) {
        onboardingJobList[onboardingJobIndex] = { ...action.job };
      }

      return {
        ...state,
        job: cJob,
        jobs: jobList,
        bookmarkedJobs: bookmarkJobs,
        unbookmarkJob: false,
        unbookmarkJobError: false,
        homepageRecommendedJobs: homepageRecommendedJobsList,
        featuredJobs: homepageFeaturedJobsList,
        similarApplicationJobs: homepageSimilarAppliedJobsList,
        homepageSavedJobs: homepageSavedJobsList,
        onboardingRecommendedJobs: onboardingJobList,
      };
    }
    case types.UNBOOKMARK_JOB_FAILED: {
      return {
        ...state,
        unbookmarkJob: false,
        unbookmarkJobError: true,
      };
    }
    // Apply job
    case types.APPLY_JOB: {
      return {
        ...state,
        applyingJob: true,
      };
    }
    case types.APPLY_JOB_SUCCEED: {
      let cJob = action.payload.appliedJob
        ? action.payload.appliedJob.id == state.job.id
          ? action.payload.appliedJob
          : state.job
        : state.job;

      let pendingList = [...state.pendingJobs];
      let rejectedList = [...state.rejectedJobs];
      let jobList = [...state.jobs];
      let recommendedList = [...state.curatedJobs];

      jobList = jobList.map(function (job) {
        return job.id == action.payload.appliedJob.id
          ? action.payload.appliedJob
          : job;
      });

      if (action.payload.updateList && action.payload.jobApplication) {
        pendingList = [action.payload.jobApplication, ...pendingList];
      }

      if (action.payload.updateRejectedList && action.payload.appliedJob) {
        rejectedList = rejectedList.filter((rejected) => {
          return rejected.job.id != action.payload.appliedJob.id;
        });
      }

      if (action.payload.updateRecommendedList && action.payload.appliedJob) {
        recommendedList = recommendedList.filter((curated) => {
          return curated.id != action.payload.appliedJob.id;
        });
      }

      return {
        ...state,
        jobs: jobList,
        applyingJob: false,
        pendingJobs: pendingList,
        rejectedJobs: rejectedList,
        curatedJobs: recommendedList,
        job: cJob, // Update current selected job on job listing page, might need to change to specific action to update this next time
        applyJobError: false,
      };
    }
    case types.APPLY_JOB_FAILED: {
      return {
        ...state,
        applyingJob: false,
        applyJobError: true,
      };
    }
    // Cancel job application
    case types.CANCELLING_JOB_APPLICATION: {
      return {
        ...state,
        cancellingJobApplication: true,
        cancellingJobApplicationError: false,
      };
    }
    case types.CANCEL_JOB_APPLICATION_SUCCEED: {
      let pendingList = [...state.pendingJobs];
      let bookmarkedList = [...state.bookmarkedJobs];
      pendingList = pendingList.filter((jobApplication) => {
        return jobApplication.id != action.jobApplicationId;
      });

      if (action.jobApplication) {
        let index = bookmarkedList.findIndex((bookmark) => {
          return bookmark.job.id == action.jobApplication.job.id;
        });

        if (index != -1) {
          bookmarkedList[index] = action.jobApplication;
        }
      }

      return {
        ...state,
        bookmarkedJobs: bookmarkedList,
        pendingJobs: pendingList,
        cancellingJobApplication: false,
      };
    }
    case types.CANCEL_JOB_APPLICATION_FAILED: {
      let pendingList = [...state.pendingJobs];
      pendingList = pendingList.filter((jobApplication) => {
        return jobApplication.id != action.jobApplicationId;
      });

      return {
        ...state,
        pendingList: pendingList,
        cancellingJobApplication: false,
        cancellingJobApplicationError: true,
      };
    }
    // For job's company's jobs
    case types.GET_JOB_COMPANY_JOBS: {
      return {
        ...state,
        fetchingJobCompanyJob: true,
      };
    }
    case types.GET_JOB_COMPANY_JOB_SUCCEED: {
      return {
        ...state,
        jobCompanyJobs: action.payload.companyJobs,
        fetchingJobCompanyJob: false,
      };
    }
    case types.GET_JOB_COMPANY_JOB_FAILED: {
      return {
        ...state,
        jobCompanyJobs: action.payload.companyJobs,
        fetchingJobCompanyJob: false,
      };
    }
    //For applied job list
    case types.FETCHING_APPLIED_JOBS: {
      return {
        ...state,
        fetchingAppliedJobs: true,
      };
    }
    case types.FETCH_APPLIED_JOBS_SUCCEED: {
      const appliedList = action.payload.nodes;
      let appliedJobs = [...state.appliedJobs, ...appliedList];

      return {
        ...state,
        appliedJobs: appliedJobs,
        appliedJobsPageInfo: action.payload.pageInfo,
        fetchingAppliedJobs: false,
        fetchingAppliedJobsError: false,
      };
    }
    case types.FETCH_APPLIED_JOBS_FAILED: {
      return {
        ...state,
        fetchingAppliedJobs: false,
        fetchingAppliedJobsError: true,
      };
    }
    case types.CLEAR_APPLIED_JOBS: {
      return {
        ...state,
        appliedJobs: [],
      };
    }
    // For pending job list
    case types.FETCHING_PENDING_JOBS: {
      return {
        ...state,
        fetchingPendingJobs: true,
      };
    }
    case types.FETCH_PENDING_JOBS_SUCCEED: {
      const pendingList = action.payload.nodes;
      let pendingJobs = [...state.pendingJobs, ...pendingList];

      return {
        ...state,
        pendingJobs: pendingJobs,
        pendingJobsPageInfo: action.payload.pageInfo,
        fetchingPendingJobs: false,
        fetchingPendingJobsError: false,
      };
    }
    case types.FETCH_PENDING_JOBS_FAILED: {
      return {
        ...state,
        fetchingPendingJobs: false,
        fetchingPendingJobsError: true,
      };
    }
    case types.CLEAR_PENDING_JOBS: {
      return {
        ...state,
        pendingJobs: [],
      };
    }
    //For Specialisation
    case types.GET_SPECIALISATION_SUCCEED: {
      return {
        ...state,
        specialisation: action.payload,
      };
    }

    // For track
    case types.GET_TRACK_SUCCEEDED: {
      return {
        ...state,
        tracks: action.payload,
      };
    }

    // For job state/regions at user landing page
    case types.GET_STATEREGION_SUCCEEDED: {
      return {
        ...state,
        stateRegions: action.payload,
      };
    }
    // For featured jobs at user landing page
    case types.FETCH_FEATURED_JOBS_SUCCEED: {
      return {
        ...state,
        featuredJobs: action.payload,
        fetchingFeaturedJobs: false,
      };
    }
    case types.FETCHING_FEATURED_JOBS: {
      return {
        ...state,
        fetchingFeaturedJobs: true,
      };
    }
    case types.FETCH_FEATURED_JOBS_FAILED: {
      return {
        ...state,
        fetchingFeaturedJobs: false,
      };
    }
    // For curated jobs at user landing page
    case types.FETCH_CURATED_JOBS_SUCCEED: {
      const jobs = action.payload.slice(0, 12); //TEMPO HACK : tempo use this hack to show 12 jobs in landing page
      return {
        ...state,
        curatedJobs: jobs,
        fetchingCuratedJobs: false,
      };
    }
    case types.FETCH_CURATED_JOBS_FAILED: {
      return {
        ...state,
        fetchingCuratedJobs: false,
      };
    }
    case types.FETCHING_CURATED_JOBS: {
      return {
        ...state,
        fetchingCuratedJobs: true,
      };
    }

    // For job types
    case types.GET_JOB_TYPE_SUCCEEDED: {
      return {
        ...state,
        jobTypes: action.payload,
      };
    }
    // For experience levels
    case types.GET_EXPERIENCE_LEVEL_SUCCEEDED: {
      return {
        ...state,
        experienceLevels: action.payload,
      };
    }
    // For job filter on landing page
    case types.UPDATE_JOB_LIST_FILTER_LANDING: {
      return {
        ...state,
        jobListFilterLanding: action.payload,
      };
    }
    // For onboarding recommended jobs
    case types.FETCHING_ONBOARDING_RECOMMENDED_JOBS: {
      return {
        ...state,
        fetchingOnboardingJobs: true,
      };
    }
    case types.FETCH_ONBOARDING_RECOMMENDED_JOBS_SUCCEED: {
      const jobList = action.jobs.map((item) => item.node);
      return {
        ...state,
        fetchingOnboardingJobs: false,
        onboardingRecommendedJobs: jobList,
      };
    }
    case types.FETCH_ONBOARDING_RECOMMENDED_JOBS_FAILED: {
      return {
        ...state,
        fetchingOnboardingJobs: false,
        fetchingOnboardingJobsError: true,
      };
    }
    // For recommended jobs after user applies for job
    case types.FETCH_RECOMMENDED_JOBS_SUCCEED: {
      const jobs = action.payload.slice(0, 12); //TEMPO HACK : tempo use this hack to show 12 jobs
      return {
        ...state,
        recommendedJobs: jobs,
        fetchingRecommendedJobs: false,
      };
    }
    case types.FETCH_RECOMMENDED_JOBS_FAILED: {
      return {
        ...state,
        fetchingRecommendedJobs: false,
      };
    }
    case types.FETCHING_RECOMMENDED_JOBS: {
      return {
        ...state,
        fetchingRecommendedJobs: true,
      };
    }
    case types.UPDATE_RECOMMENDED_JOBS: {
      return {
        ...state,
        bookmarkedRecommendedJobs: action.bookmarkedRecommendedJobs,
      };
    }
    case types.FETCHING_SIMILAR_JOBS_UNDER_JOB_PAGE: {
      return {
        ...state,
        fetchingSimilarJobsUnderJobsPage: true,
      };
    }
    case types.FETCH_SIMILAR_JOBS_UNDER_JOB_PAGE_SUCCEED: {
      return {
        ...state,
        similarJobsUnderJobPage: action.payload,
        fetchingSimilarJobsUnderJobsPage: false,
      };
    }
    case types.FETCH_SIMILAR_JOBS_UNDER_JOB_PAGE_FAILED: {
      return {
        ...state,
        fetchingSimilarJobsUnderJobsPage: false,
      };
    }
    case types.FETCHING_SIMILAR_APPLICATION_JOBS: {
      return {
        ...state,
        fetchingSimilarApplicationJobs: true,
      };
    }
    case types.FETCH_SIMILAR_APPLICATION_JOBS_SUCCEED: {
      const jobs = action.payload.slice(0, 12); //TEMPO HACK : tempo use this hack to show 12 jobs
      return {
        ...state,
        similarApplicationJobs: jobs,
        fetchingSimilarApplicationJobs: false,
      };
    }
    case types.UPDATE_SIMILAR_APPLICATION_JOBS: {
      return {
        ...state,
        similarApplicationJobs: action.jobs,
      };
    }
    case types.FETCH_SIMILAR_APPLICATION_JOBS_FAILED: {
      return {
        ...state,
        fetchingSimilarApplicationJobs: false,
      };
    }
    case types.FETCHING_HOMEPAGE_TRENDING_KEYWORDS: {
      return {
        ...state,
        fetchingTrendingJobKeywords: true,
      };
    }
    case types.FETCH_HOMEPAGE_TRENDING_KEYWORDS_SUCCEED: {
      return {
        ...state,
        trendingJobKeywords: action.payload,
        fetchingTrendingJobKeywords: false,
      };
    }
    case types.FETCH_HOMEPAGE_TRENDING_KEYWORDS_FAILED: {
      return {
        ...state,
        fetchingTrendingJobKeywords: false,
      };
    }

    // For job alerts
    case types.UPDATE_JOB_ALERT_TOGGLE: {
      const currentSyncToggle =
        action.syncToggle != undefined
          ? action.syncToggle
          : state.syncJobAlertToggle;
      return {
        ...state,
        showJobAlertToggle: action.status,
        syncJobAlertToggle: currentSyncToggle,
      };
    }
    case types.FETCHING_JOB_ALERTS: {
      return {
        ...state,
        fetchingJobAlerts: true,
      };
    }
    case types.FETCH_JOB_ALERTS_SUCCEED: {
      return {
        ...state,
        jobAlerts: action.jobAlerts,
        fetchingJobAlerts: false,
      };
    }
    case types.FETCH_JOB_ALERTS_FAILED: {
      return {
        ...state,
        fetchingJobAlerts: false,
        fetchingJobAlertsError: true,
      };
    }
    case types.CREATING_JOB_ALERT: {
      return {
        ...state,
        creatingJobAlert: true,
      };
    }
    case types.CREATE_JOB_ALERT_SUCCEED: {
      return {
        ...state,
        jobAlerts: action.jobAlerts,
        jobAlert: action.jobAlert,
        creatingJobAlerts: false,
      };
    }
    case types.CREATE_JOB_ALERT_FAILED: {
      return {
        ...state,
        creatingJobAlert: false,
        creatingJobAlertError: true,
      };
    }
    case types.DELETING_JOB_ALERT: {
      return {
        ...state,
        deletingJobAlert: true,
      };
    }
    case types.DELETE_JOB_ALERT_SUCCEED: {
      return {
        ...state,
        jobAlerts: action.jobAlerts,
        deletingJobAlert: false,
        reachedMaxJobAlerts: false,
      };
    }
    case types.DELETE_JOB_ALERT_FAILED: {
      return {
        ...state,
        deletingJobAlert: false,
        deletingJobAlertError: true,
      };
    }
    case types.REACHED_MAX_JOB_ALERTS: {
      return {
        ...state,
        reachedMaxJobAlerts: true,
      };
    }
    case types.JOB_ALERT_EXISTS: {
      return {
        ...state,
        jobAlertExists: true,
      };
    }
    case types.REPLACING_JOB_ALERT: {
      return {
        ...state,
        replacingJobAlert: true,
      };
    }
    case types.REPLACE_JOB_ALERT_SUCCEED: {
      return {
        ...state,
        jobAlerts: action.jobAlerts,
        jobAlert: action.jobAlerts[action.jobAlerts.length - 1],
        replacingJobAlert: false,
        reachedMaxJobAlerts: false,
      };
    }
    case types.REPLACE_JOB_ALERT_FAILED: {
      return {
        ...state,
        replacingJobAlert: false,
        replacingJobAlertError: true,
      };
    }
    case types.UPDATING_JOB_ALERT: {
      return {
        ...state,
        updatingJobAlert: true,
      };
    }
    case types.UPDATE_JOB_ALERT_SUCCEED: {
      return {
        ...state,
        jobAlert: action.jobAlert,
        updatingJobAlert: false,
      };
    }
    case types.UPDATE_JOB_ALERT_FAILED: {
      return {
        ...state,
        updatingJobAlert: false,
        updatingJobAlertError: true,
      };
    }
    case types.GET_JOB_ALERT: {
      return {
        ...state,
        fetchingJobAlert: true,
      };
    }
    case types.GET_JOB_ALERT_SUCCEED: {
      return {
        ...state,
        jobAlert: action.jobAlert,
        fetchingJobAlert: false,
      };
    }
    case types.GET_JOB_ALERT_FAILED: {
      return {
        ...state,
        fetchingJobAlert: false,
        fetchingJobAlertError: true,
      };
    }
    case types.VISITOR_ADDING_JOB_ALERT: {
      return {
        ...state,
        visitorAddingJobAlert: true,
      };
    }
    case types.VISITOR_ADD_JOB_ALERT_SUCCEED: {
      return {
        ...state,
        visitorAddingJobAlert: false,
      };
    }
    case types.VISITOR_ADD_JOB_ALERT_FAILED: {
      return {
        ...state,
        visitorAddingJobAlert: false,
        error: action.error,
      };
    }
    case types.VISITOR_UNSUBSCRIBING_JOB_ALERT: {
      return {
        ...state,
        visitorUnsubscribingJobAlert: true,
      };
    }
    case types.VISITOR_ADD_JOB_ALERT_SUCCEED: {
      return {
        ...state,
        visitorUnsubscribingJobAlert: false,
      };
    }
    case types.VISITOR_UNSUBSCRIBE_JOB_ALERT_FAILED: {
      return {
        ...state,
        visitorUnsubscribingJobAlert: false,
      };
    }
    case types.CLEAR_JOB_ALERTS: {
      return {
        ...state,
        jobAlerts: [],
        jobAlert: {},
        visitorJobAlert: "",
      };
    }

    // For search suggestions
    case types.FETCHING_SEARCH_DATA: {
      return {
        ...state,
        fetchingSearchSuggestions: true,
      };
    }
    case types.FETCH_SEARCH_DATA_SUCCEED: {
      let searchSuggestions = action.payload.searchSuggestions[0][1];
      let cSuggestion = searchSuggestions[0];
      return {
        ...state,
        searchData: action.payload.searchData,
        searchSuggestion: cSuggestion,
        fetchingSearchSuggestions: false,
      };
    }
    case types.FETCH_SEARCH_DATA_FAILED: {
      return {
        ...state,
        fetchingSearchSuggestions: false,
      };
    }
    case types.CLEAR_SEARCH_SUGGESTIONS: {
      return {
        ...state,
        searchData: [],
      };
    }
    case types.UPDATE_SELECT_ALL_JOB_FILTER: {
      return {
        ...state,
        jobListFilter: action.filters,
      };
    }
    case types.UPDATE_ASHLEY_TOGGLE_DIALOG: {
      return {
        ...state,
        openAshleyInfoDialog: action.payload,
      };
    }
    case types.CLOSE_ASHLEY_ERROR_DIALOG: {
      return {
        ...state,
        ashleyRecommendsError: false,
        ashleyRecommendsErrorCode: "",
      };
    }
    case types.FETCH_HOMEPAGE_LOGO: {
      return {
        ...state,
        homepageLogo: action.payload
          .map((value) => ({ value, sort: Math.random() }))
          .sort((a, b) => a.sort - b.sort)
          .map(({ value }) => value),
      };
    }
    case types.FETCH_TRENDING_CHIP: {
      return {
        ...state,
        trendingChip: action.payload,
      };
    }
    case types.FETCH_HOMEPAGE_ADS: {
      return {
        ...state,
        homepageAds: action.payload,
      };
    }

    case types.UPDATE_JOB_ALERT_EMAIL: {
      return {
        ...state,
        jobAlertEmail: action.payload,
      };
    }

    case types.SHOW_VISITOR_JOB_ALERT_DIALOG: {
      return {
        ...state,
        showVisitorJobAlert: action.payload,
      };
    }
    case types.SHOW_VISITOR_JOB_ALERT_SUCCESS: {
      return {
        ...state,
        visitorJobAlertSuccess: action.payload,
      };
    }

    case types.UPDATE_FOR_YOU_PAGINATION_COUNT: {
      return {
        ...state,
        forYouPaginationCounter: action.counter,
      };
    }

    case types.FETCHING_FOR_YOU: {
      return {
        ...state,
        fetchingForYou: action.status,
      };
    }

    case types.FETCHING_RECENT_VIEWED_JOBS: {
      return {
        ...state,
        fetchingJobList: true,
      };
    }

    case types.FETCH_RECENT_VIEWED_JOBS_SUCCEED: {
      const jobList = action.payload.jobList;
      let pageInfo = action.payload.pageInfo;
      const historyExist = action.payload.historyExist;

      return {
        ...state,
        fetchingForYou: false,
        fetchingJobList: false,
        historyExist: historyExist,
        jobs: jobList,
        error: false,
        jobPageInfoV1: pageInfo,
      };
    }

    case types.FETCH_RECENT_VIEWED_JOBS_FAILED: {
      let cFetchingJobList =
        action.currentLoadingJobsKey === state.currentLoadingJobsKey
          ? false
          : state.fetchingJobList;

      return {
        ...state,
        fetchingForYou: false,
        fetchingJobList: false,
        historyExist: false,
        error: true,
        errorMessage: action.errorMessage,
        fetchingTotalJobsPages: false,
      };
    }

    case types.FOLLOW_SINGLE_JOB_COMPANY_SUCCEED: {
      return {
        ...state,
        job: {
          ...state.job,
          company: {
            ...state.job.company,
            bookmark: action.payload.newBookmarkStatus ?? false,
          },
        },
      };
    }

    case types.SELECTED_JOB_ID: {
      return {
        ...state,
        selectedJobId: action.payload,
      };
    }

    // To manage predefined job filter flow
    case types.UPDATE_CHECKING_URL_PARAM_FOR_JOB_FILTER_STATUS: {
      return {
        ...state,
        doneCheckingUrlParamForJobFilter: action.status,
      };
    }

    case types.UPDATE_JOB_SEARCH_KEYWORD: {
      return {
        ...state,
        jobListFilter: {
          ...state.jobListFilter,
          keyword: action.payload,
        },
      };
    }

    case types.UPDATE_JOB_SEARCH_OPTION_KEYWORD: {
      return {
        ...state,
        jobSearchKeyword: action.payload,
      };
    }

    case types.UPDATE_SEARCH_FEEDBACK: {
      return {
        ...state,
        searchFeedback: action.payload,
      };
    }

    case types.UPDATE_LOADING_JOBS: {
      return {
        ...state,
        isLoadingJobs: action.status,
      };
    }

    case types.UPDATE_JOB_LIST_TAB: {
      return {
        ...state,
        jobListTab: action.tab,
        pageFirstLoad: false,
      };
    }

    default: {
      return state;
    }
  }
};
